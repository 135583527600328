import Address from "../../Components/Address";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import RecentPost from "../../Components/RecentPost";
import SocialSideBar from "../../Components/SocialSideBar";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./style.css";
import SideBarContactForm from "../../Components/SideBarContactForm";
import { useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, LinkedinShareButton } from "react-share";
import { FacebookIcon, TwitterIcon, WhatsappIcon, LinkedinIcon } from "react-share";
import { useDispatch, useSelector } from "react-redux";
import BlogDetailService from "../../services/BlogDetailService";
import { BLOGDETAIL } from "../../redux/types";
import { useEffect } from "react";
import parse from 'html-react-parser';

const BlogDetail = () => {
  const location = useLocation();
  const canonicalUrl = `https://alctravels.com${location.pathname}`

  window.scrollTo(0, 0)
  const blogId = useParams().blogId;

  const dispatch = useDispatch();

  let data = useSelector((state) => state?.blogDetail);
  if (data === undefined) data = [];

  useEffect(() => {
    BlogDetailService.getDetailedBlog(blogId)
      .then((res) => {
        dispatch({
          type: BLOGDETAIL,
          payload: res,
        });
        console.log(res);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);
  const bannerImage = "https://admin.alctravels.com/storage/"+data?.data?.featured_image;
  const title = data?.data?.title;
  const pageTitle = "ALC Travels - " + title;
  const webURL = window.location.href;
  console.log(webURL)
  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <SocialSideBar />
      <Header />
      <div className="page-banner">
        <img src={bannerImage} className="w-100" alt=""></img>
        <div className="pagebanner_heading">
          <h1>{title}</h1>
        </div>
      </div>
      <div className="section_blogdetail">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-xl-9">
              <div className="content">
                {parse(data?.data?.description+"")}
                <p style={{ fontWeight: "bolder" }}>Share Now</p>
                <div className="social_icon">
                  <FacebookShareButton url={webURL} style={{ margin: "0px 5px" }}>
                    <FacebookIcon round={true} iconFillColor="white" target="_blank" size={"35px"} />
                  </FacebookShareButton>
                  <WhatsappShareButton url={webURL} style={{ margin: "0px 5px" }}>
                    <WhatsappIcon round={true} iconFillColor="white" target="_blank" size={"35px"} />
                  </WhatsappShareButton>
                  <TwitterShareButton url={webURL} style={{ margin: "0px 5px" }}>
                    <TwitterIcon round={true} iconFillColor="white" target="_blank" size={"35px"} />
                  </TwitterShareButton>
                  <LinkedinShareButton url={webURL} style={{ margin: "0px 5px" }}>
                    <LinkedinIcon round={true} iconFillColor="white" target="_blank" size={"35px"} />
                  </LinkedinShareButton>
                </div>
              </div>
            </div>

            <div className="col-12 col-xl-3">
              <SideBarContactForm />
              <img src="./season_banner.svg" alt="" className="w-100 my-4" />
              <RecentPost /> {/*MAXIMUM POST MUST BE TEN  AT A TIME*/}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <Address />
      </div>
      <Footer />
    </div>
  );
};

export default BlogDetail;

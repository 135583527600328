import Address from "../../Components/Address";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "./style.css";
import React from "react";
import { useState } from "react";
import SideBarContactForm from "../../Components/SideBarContactForm";
import SocialSideBar from "../../Components/SocialSideBar";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const ContactUs = () => {
  const location = useLocation();
  const canonicalUrl = `https://alctravels.com${location.pathname}`

  const [value, setValue] = useState();

  const pageTitle = "ALC Travels - Contact Us";
  window.scrollTo(0,0);

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <SocialSideBar/>
      <Header />
      <div className="page-banner">
        <img src="./contactus.svg" className="w-100" alt=""></img>
        <div className="pagebanner_heading">
          <h1>Contact Us</h1>
        </div>
      </div>
      <div className="section_contactus">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6 col-xl-4">
              <div className="contact-head mb-4">
                <h3>Have an Enquiry? Get in Touch</h3>
                <SideBarContactForm />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-4">
              <div className="address">
                <h2>ALC Travels - bhikaji cama place</h2>
                <h3>Corporate office</h3>
                <div className="info mt-1">
                  <img src="./buildings.svg" alt="" className="w-100" />
                  <p>404 - Bhikaji Cama Bhawan, Bhikaji Cama  Place,  Rama Krishna Puram, New Delhi, Delhi 110066, (India)</p>
                </div>
                <div className="info">
                  <img src="./call.svg" alt="" className="w-100" />
                  <p>+91-1141646363</p>
                </div>
                <div className="info">
                  <img src="./email.svg" alt="" className="w-100" />
                  <p>info@alctravels.com</p>
                </div>
                <hr style={{margin : "0.2rem 0"}}/>
                <h2>ALC Travels - civil lines (delhi) </h2>
                <h3>branch office</h3>
                <div className="info mt-4">
                  <img src="./buildings.svg" alt="" className="w-100" />
                  <p>Plot No- 2, Racquet Court Road, Civil Lines, Delhi, 110054, (India)</p>
                </div>
                <div className="info">
                  <img src="./call.svg" alt="" className="w-100" />
                  <p>+91-1141646363</p>
                </div>
                <div className="info">
                  <img src="./email.svg" alt="" className="w-100" />
                  <p>info@alctravels.com</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-2">
              <div className="address">
                <div className="toll-number">
                  <h4>TOLL FREE NUMBER</h4>
                  <h5>1800 309 9957</h5>
                  <a href="https://www.google.com/maps?ll=28.568766,77.187068&z=14&t=m&hl=en&gl=IN&mapclient=embed&cid=16360612708431680179" target="_blank">VIEW ON GOOGLE MAP</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14016.132820297975!2d77.1870676!3d28.5687656!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1d86a76247b7%3A0xe30c929379cfd2b3!2sALC%20Travels!5e0!3m2!1sen!2sin!4v1700297463302!5m2!1sen!2sin"
          width={"100%"}
          height={300}
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>

      <div>
        <Address />
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;

import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./style.css";
import Slider from "react-slick";

const Banner = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state?.tableHeader);
  const bannerSliders = data?.data?.bannerSliders || [];

  console.log(bannerSliders);

  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  var settings = {
    slidesToShow: 1,
    dots: false,
    infinite: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  if (!data) {
    return <div>Loading...</div>;
  }

  if (data.error) {

    return <section className="section_banner">Error loading images</section>;
  }

  return (
    <React.Fragment>
      <section className="section_banner">
        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <Slider ref={slider => {
              sliderRef = slider;
            }}
              {...settings}>
              {bannerSliders.map((item, index) => (
                <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                  <a href={item.page_url} target="_blank">{item.banner_image && <img src={`https://admin.alctravels.com/storage/${item.banner_image}`} className="d-block w-100" alt={`Slide ${index}`} />}</a>
                </div>
              ))}
            </Slider>
          </div>
          <button className="carousel-control-prev" type="button" onClick={previous}>
            <span className="carousel-control-prev-icon" aria-hidden="true"><img src="./blue-arrow.png" alt="" /></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" onClick={next}>
            <span className="carousel-control-next-icon" aria-hidden="true"><img src="./blue-arrow.png" alt="" /></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Banner;

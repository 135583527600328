import React from "react";
import "./style.css";
import Slider from "react-slick";
import BlogCard from "../../BlogCard";
import { useSelector } from "react-redux";

const LatestBlog = () => {
  let data = useSelector((state) => state?.tableHeader);
  const blogs = data?.data?.blogs || [];
  var settings = {
    dots: true,
    slidesToShow: 4,
    infinite: true,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };


  return (
    <React.Fragment>
      <section className="section-latestblog">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <Slider {...settings}>
                {blogs.map((eachBlog, index) => (
                  <div key={index}>
                    <BlogCard
                      key={index}
                      id={eachBlog.id}
                      description={eachBlog.description}
                      image={eachBlog.featured_image}
                      title={eachBlog.title}
                      date={eachBlog.created_at}
                      pageUrl={eachBlog.page_url}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default LatestBlog;

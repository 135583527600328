import Address from "../../Components/Address";
import CruiseCard from "../../Components/CruiseCard";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Search from "../../Components/Search";
import { useSelector, useDispatch } from "react-redux";
import "./style.css";
import CruiseApiService from "../../services/CruiseApiService";
import { useEffect, useState } from "react";
import { CRUISEDETAIL, TABLEHEADER } from "../../redux/types";
import Loader from "../LoaderSpinner";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";

const Cruise = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const canonicalUrl = `https://alctravels.com${location.pathname}`

  useEffect(() => {
    setTimeout(() => {
      CruiseApiService.getCruisePage(pageNum)
        .then((res) => {
          dispatch({
            type: CRUISEDETAIL,
            payload: res,
          });
          console.log(res);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log("error", error);
        });
    }, 3000)

  }, []);

  const data = useSelector((state) => state?.cruiseDetail);
  const cruiseData = data?.data || [];
  const cruiseCardData = cruiseData.data || [];
  const ads = data?.ads || [];
  const [loading, setLoading] = useState(true);
  const [pageNum, setPageNum] = useState(1);
  console.log(cruiseCardData)
  const prevLink = cruiseData?.links?.[0]?.url || null;
  const nextLink = cruiseData?.links?.[2]?.url || null;
  console.log(cruiseCardData.length)

  const [expanded, setExpanded] = useState(false);

  const toggleText = () => {
    setExpanded(!expanded);
  };

  const originalText = `
  <p className="expanded-heading">Cruise services </p>
<div>Want to hop on a thrilling adventure amidst the sea? ALC Travels is all set to make your life 
extremely exciting as they work with all major cruise lines including Norwegian Cruise Line, 
Royal Caribbean, Carnival Cruise, MSC Cruises, Resort World, Cunard, Princess, Costa, 
Holland America, Disney, and more. </div>
<div>We are indulged in offering the best-in-class sea voyage experience to our travelers. Some of 
the most unique and luxurious cruise itineraries that we offer are: </div>
<ul>
<li>Cunard’s “Queen Mary 2": Between Southampton, U.K. Brooklyn, New York. </li>
<li>Viking’s ocean sails between Bergen, Norway, Stockholm, Sweden. </li>
<li>Virgin Voyages’ “Scarlet Lady” sails from Miami to Key West and Bimini. </li>
<li>Silverseas Cruises’ “Silver Nova” sails between Seward, Alaska, Vancouver, Canada. </li>
<li>Seabourn’s “Seabourn Ovation” sails from Barcelona to Rome. </li>
<li>Windstar’s “Wind Surf”: A Remote Caribbean Getaway. </li>
<li>Regent Seven Seas sails from Athens to Monte Carlo </li>
<li>The Oberoi Zahra in Nile Egypt </li>
<li>Avalon waterways in Europe </li>
<li>Halong bay cruise in Vietnam </li>
</ul>
<div>Some of the unique River cruising that you must not miss are The Oberoi Zahra: for Nile 
cruises, Avalon waterways in Europe, and Halong bay cruise. </div>
<p className="expanded-heading">How will our service make you explore the best of the world? </p>
<div>Hop on the Antarctic cruises that begin and end in South American ports like Ushuaia or 
Buenos Aires. They will further take you on an adventurous trip as per the weather. No matter 
what the destination will be, your trip on board with us will be a memorable one. We give you 
the opportunity to experience once in a lifetime expedition cruises like: </div>
<ul>
<li>Lindblad Expeditions-National Geographic: Experienced and Iconic. </li>
<li>Ponant: The Ultimate in Luxurious Antarctic posh expedition Cruises & yacht, Quark 
Expeditions Antarctic cruises. </li>
<li>Most Antarctic cruises begin and end in South American ports like Ushuaia or Buenos 
Aires. </li>
</ul>
<p className="expanded-heading">What sets us apart? </p>
<div>Relax, rejuvenate, and rewind by exploring the best destinations while being on our cruise. 
Keep your mind, body, and soul free and enjoy the lavish food and complete entertainment that 
you will receive on our cruises. </div>
<div>We suggest you to explore the world by booking the best cruises, namely: </div>
<ul>
<li>Norwegian Cruise Line </li>
<li>Royal Caribbean </li>
<li>Carnival Cruise </li>
<li>MSC Cruises </li>
<li>Resort World </li>
<li>Cunard </li>
<li>Princess </li>
<li>Costa </li>
<li>Holland America </li>
<li> Disney Cruises </li>
</ul>
Get in touch with ALC Travels to book an adventurous cruise voyage today!
  `;

  const readingText = !expanded ? originalText.slice(0, 200) + "...." : originalText;
  const pageTitle = "ALC Travels - Cruises";

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Header />
      <div className="page-banner">
        <img src="./Cruisebanner.svg" className="w-100" alt=""></img>
        <div className="pagebanner_heading">
          <h1>Cruise</h1>
        </div>
      </div>
      <Search />
      {loading ? <div><Loader /></div> : <>
        {cruiseCardData.length === 0 ? <div className="my-5" style={{ textAlign: "center" }}><Link to={'/contact-us'} className="btn btn-primary">Contact Us For Packages</Link></div> : <>
          <div className="section_cruise">
            <div className="container-fluid">
              <div className="row">
                {cruiseCardData.map((elem) => (
                  <div className="col-12 col-md-6 col-lg-4 col-xl-3" key={elem.cruise_id}>
                    <CruiseCard title={elem.cruise_title || ""} dayNight={elem.cruise_daynight || ""} cruiseImage={elem.cruise_image || ""} cruisePrice={elem.cruise_price || ""} cruisePdf={elem.cruise_document || ""} cruiseId={elem.cruise_id} popUpImage={ads.POPUPAD} />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="pagination_card">
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className="page-item">
                  <a className="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li className="page-item page-circle">
                  <button className="btn btn-primary" type="button" disabled={prevLink === null ? "false" : "true"} onClick={prevLink}>&larr;</button>
                </li>
                <li className="page-item page-circle">
                  <a className="page-link" href="#">
                    {data.data.current_page}
                  </a>
                </li>
                <li className="page-item page-circle">
                  <button className="btn btn-primary" type="button" disabled={nextLink === null ? "false" : "true"} onClick={nextLink}>&rarr;</button>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </>}

        <section className="section_heading" style={{ padding: "10px 20px 10px 20px" }}>
          <div className="container-fluid">
            <p style={{ fontSize: '17px' }}>
              {parse(readingText)}{' '}
              <Link to="#" onClick={toggleText}>
                {expanded ? 'Read Less...' : 'Read More…'}
              </Link>
            </p>
          </div>
        </section></>}
      <div className="mt-5">
        <Address />
      </div>
      <Footer />
    </div>
  );
};

export default Cruise;

import React from 'react'
import Header from '../../Components/Header'
import Address from '../../Components/Address'
import Footer from '../../Components/Footer'
import { Helmet } from "react-helmet";
import './index.css'
import { useLocation } from 'react-router-dom';

function PrivacyPolicy() {
    const pageTitle = "ALC Travels - Privacy and Policy";
    const location = useLocation();
    const canonicalUrl = `https://alctravels.com${location.pathname}`
    window.scroll(0, 0);
    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <Header />
            <div className="privacy-and-policy">
                <div className="heading">
                    <h2>PRIVACY & POLICY</h2>
                </div>
                <div className="data w-100">
                    <p>Arise Leisure and Corporate Travels Private Limited (hereinafter referred as “ALC Travels”, “we”, “us” or
                        “our”) understands and respects the importance of privacy of its users (hereinafter referred as “You” or
                        “Your”). This Privacy Policy sets out the basis on which any personal data we collect from You, or that
                        You provide us, via the website www.alctravels.com (hereinafter referred as “the website”) will be
                        processed by us. Confidentiality of information provided by You will be maintained. ALC Travels includes
                        its Subsidiary Companies. will be processed by us. Confidentiality of information provided by You will be
                        maintained. ALC Travels includes its Subsidiary Companies.
                    </p><br />
                    <p>This Privacy Policy applies to any person/s who book/purchase/intend to purchase/enquire about any
                        product(s) and/or service(s) made available by ALC Travels through its website as well as offices
                        including corporate office, branch offices and preferred sales partners. By making a booking/purchase or
                        submitting Your personal data via the website or any offices, You agree to the transfer, storing or
                        processing of such information. If You do not agree to Veena World’s use of Your personal data we
                        cannot accept Your booking and You should not use the website.
                    </p><br />
                    <p><h3>COLLECTION AND USE OF PERSONAL INFORMATION</h3></p>
                    <p>You may be asked for personal information including but not limited to name, address, email address,
                        telephone number, date of birth, credit/debit card number (in encrypted form) with expiration date,
                        banking details, Aadhar Details, PAN Details, Visa related Documents (photographs, Bank Details,
                        Business/Company Details, etc.), Passport Details (passport number, issue date, expiry date), mobile
                        number, Professional Qualifications/details, etc., anytime while making a booking/purchase via the
                        website or any other customer touch points. We may keep a record of Your email or other
                        correspondence, and if You call us by telephone, we may monitor and/or record phone conversations
                        for training and customer service reasons. We may collect details of Your computer and visits to this Site
                        (including, but not limited to, traffic data, location data, IP address, operating system and browser type)
                        for system administration purposes and to report aggregate information to our advertisers. This is
                        statistical data about our users' browsing actions and patterns, and does not identify any individual.
                    </p><br />
                    <p>In some cases we may also need to collect more sensitive personal data such as information regarding
                        Your medical conditions, disabilities and special requirements along with medical Certificates supporting
                        the same. This information is allowed to be stored by You and is collected in order to arrange for the
                        services You are requesting and this Information is presented to You at the time of making a
                        booking/purchase so that the process of bookings /purchase is completed expeditiously. This
                        Information may also be used internally for research, analysis and auditing. Cookies and other
                        information may be used to optimize the viewing experience, troubleshoot any problems and serve
                        better content.
                    </p><br />
                    <p><h3>PURPOSE OF COLLECTING PERSONAL INFORMATION </h3></p>
                    <p>
                        <li>Communicate with You regarding bookings/purchase</li>
                        <li>Send booking confirmations</li>
                        <li>Keep You updated regarding transaction status</li>
                        <li>Sending tour related information on daily basis</li>
                        <li>Send verification/alert message(s) or email(s)</li>
                        <li>Notify and update the changes in bookings. </li>
                        <li>Notify and update the changes in bookings. </li>
                        <li>Allow our customer service department to contact You.</li>
                        <li>Detecting and prevent fraudulent or criminal activity.</li>
                        <li> Carry out our obligations arising in connection with any contracts entered into between You and us,
                            or between You and a third party supplier. </li>
                        <li> Ensure that content from the website is presented in the most effective manner for You. </li>
                    </p><br />
                    <p><h3>COOKIES</h3></p>
                    <p>This Site uses cookies to distinguish You from other users. Cookies are small pieces of information that
                        are stored by Your browser on Your device's hard drive when You visit the website. Most Web browsers
                        automatically accept cookies. You can control and delete how and whether cookies will be accepted by
                        Your browser by changing the options on Your web browser or using certain software programs but
                        disabling them may affect the functionality of this website. Once You access the website, You consent to
                        our use of cookies. Some of these cookies are essential to make the website user friendly and work
                        properly, to allow You to make Your booking/purchases, and to enable us to serve You better and more
                        efficiently. Other types of cookies help us to provide You with a good experience when You browse our
                        Site, and also allow us to improve our Site or the way we provide our service to customers. No Personal
                        Information is collected during this process. The information so collected during this process, is
                        anonymous and does not link online actions to You.</p><br />
                    <p>We may record anonymous, non-personal information (not including Your name, address, email address
                        or telephone number) about Your visits to this and other web sites in order to measure advertising
                        effectiveness. We may also collect non personal information about Your visit to our website, based on
                        Your browsing (click stream) activities. This information may include the pages browsed and products
                        and services viewed or booked for example. None of this information is passed to any third party, and is
                        used solely by us to provide You with a better user experience on the Website.</p><br />
                    <p>In order to manage and develop our offers and to provide You with better products and services tailored
                        to Your individual interests and needs, we may also use cookies to display any advertisement to You
                        while You are on the Website or to send You offers via e-mails focusing particularly on destinations
                        which may be of Your interest. The third party advertising companies may also employ technology that
                        is used to measure the effectiveness of the advertisements. All such information is anonymous. This
                        anonymous information is collected through the use of a pixel tag, which is an industry standard
                        technology and is used by all major websites. They may use this anonymous information about Your
                        visits to the Website in order to provide advertisements about goods and services of potential interest
                        to You. </p><br />
                    <p><h3>Some information that You should have about Cookies: </h3></p>
                    <p>
                        <li>Certain features on the website are only available through the use of cookies.</li>
                        <li>Cookies allow You to log in without having to type Your login name each time (only Your password is
                            needed) and identify the User and maintain their signed-in status.</li>
                        <li> Most cookies are "session cookies," meaning that they are automatically deleted from the User’s
                            hard drive at the end of a session. “Persistent cookies” remain in place across multiple visits to our
                            websites.</li>
                        <li> You are free to decline the cookies if their browser permits, although doing so may interfere with use
                            of the website</li>
                    </p><br />
                    <p><h3>We may use Cookies to:</h3></p>
                    <p>
                        <li>Measure the entry and exit points of visitors to the Site and respective number of visitors to various
                            pages and sections of the Site and details of searches performed.
                        </li>
                        <li> Measure the usage of advertising banners and other click through from the Site.</li>
                        <li>Understand and analyze trends, to administer the site, to learn about user behavior on the site, and
                            to gather demographic information about our user base as a whole.</li>
                        <li> We may use this information in our marketing and advertising services.</li>
                        <li> Pixel tags enable us to send email messages in a format customers can read, and they tell us whether
                            the mail has been opened. We may use this information to reduce or eliminate messages sent to
                            customers. </li>
                    </p><br />
                    <p><h3>THIRD PARTY WEBSITE LINKS</h3></p>
                    <p>You may be offered automatic links to other internet websites at various points that may be relevant to
                        a particular aspect of this website. These third party website owners may use combined information
                        about Your visits to our Site and other sites in order to provide advertisements about related goods and
                        services that may be of Your interest. Please note that we do not share any of Your personal Information
                        with these third party website owners unless You give us permission, in writing to do so. These third
                        party website owners may seek to use cookies and pixel tags to track Session Data about the ads You
                        have seen and types of things in which You appear interested. This does not indicate that ALC Travelsis
                        necessarily associated with any of these other websites.
                    </p><br />
                    <p>Clicking on one of these automatic links, You are no longer on the website of Veena World. We take no
                        responsibility whatsoever for these other websites or any information contained in them. Please check
                        the Terms and Conditions and review the privacy policies before You submit any personal data to these
                        other websites as we do not accept any responsibility or liability for their policies</p><br />
                    <p><h3>PARTIES WITH WHOM YOUR INFORMATION IS SHARED </h3></p>
                    <p>In order to improve service efficiency, Your personal information may be shared with our Holding
                        Company, Subsidiary Company, associate Companies, Subsidiary of our Holding company, or an entity
                        which controls, is controlled by or is under common control. Such information will be shared under
                        controlled and secure circumstances. In any event of a merger, or sale or re-structuring or if we sell/
                        transfer/assign the business or part thereof, to any of our group companies, then any and all the
                        Customer’s personal information will be transferred to the appropriate party.</p><br />
                    <p>We may share Your personal information with our Service Providers such as Hotels, Airlines, Destination
                        Management Companies, Transport Service Providers or any other suppliers involved in fulfilling Your
                        booking/purchase. Please take note that while making a booking/purchase with us, You authorize us to
                        share Your information with the said service providers/suppliers. However, You are advised to go
                        through the privacy policies of the respective service provider/supplier whose services You choose to
                        avail as how the said service providers/suppliers use the information shared with them is beyond our
                        purview. We may share Your personal information with our Business Partners like Banks, Insurance
                        Companies, International SIM Card providers, Visa Consulates, etc. </p><br />
                    <p>We may disclose Your personal information if and when required by law. The information will be
                        disclose when it is necessary to comply with a court order, any on-going judicial proceeding, or other
                        legal process served on us or to exercise the legal rights or defend against legal claims in bona fide
                        circumstances. We may also disclose the information if the disclosure is reasonably necessary.
                    </p><br />
                    <p><h3>MARKETING USE </h3></p>
                    <p>We may use the information generated from an enquiry or a booking/purchase made by You on this
                        website, to contact You by telephonic means and/or by electronic means (e-mail) with information and
                        offers relating to the products about which You enquired. At any given point of time You can ask us to
                        not process Your personal data for marketing purposes by sending a request to unsubscribe the e-mail
                        address mentioned herein after: info@alctravels.com
                    </p><br />
                    <p>We will send You information and offers vie email relating to products offered by us if You have signed
                        up (opt in) to receive such marketing. We will not pass Your contact details to any other third party
                        other than our group companies for marketing purposes unless You have expressly agreed that we may
                        do so. </p><br />
                    <p>We frequently provide sponsorships as a promotional activity and provide opportunities to You to win
                        great travel related prizes. Personal Information collected by us for such activities may include contact
                        information and survey questions. We use Your personal information to notify the contest winners. As a
                        part of our marketing policy You may receive promotional emails, newsletters, exclusive promotions
                        offering special deals, new product launches, etc. </p><br />
                    <p>Opinions, suggestions and feedback given by You are highly appreciated by the Management of Veena
                        World. In order to collect and gather opinions from You online surveys are conducted by us. All the
                        surveys are anonymous unless otherwise stated. Participation in these surveys is entirely optional but it
                        is advisable to participate and give Your opinions as they will be taken into consideration and used to
                        make improvements not only to the Website but also to improve products and services provided to You. </p><br />
                    <p>You also give us your consent to use your photographs captured during Tours to be published on the
                        website for marketing and advertising purpose.
                    </p><br />
                    <p><h3>DATA PROTECTION AND SECURITY</h3></p>
                    <p>Information transmitted via the internet is never completely secure and we cannot guarantee the
                        security of Your transmitted data therefore any transmission done via the internet is at Your own risk.
                        Website has stringent security measures in place to protect the loss, misuse, and alteration of the
                        information, and appropriate security measures and certifications are in place to protect Your personal
                        data. We use SSL (Secure Socket Layer) Technology to encrypt all the information transmitted by You. All
                        information You provide to us is stored on our secure servers. While no system is full-proof, including
                        ours, we will do our best to ensure Your data remains safe with us. In no circumstance, will ALC
                        Travelsbe held responsible for any loss of Your personal information due to malicious attempts of
                        unlawful interception of Your personal data or private communications by a third party. By browsing the
                        website or making a booking/purchase You agree with such limitation and exposure. </p><br />
                    <p>Certain parts of the website are accessible only after You sign-in. Passwords provided by us or chosen by
                        You to sign-in must be kept confidential. We ask You not to share a password with anyone. You may
                        withdraw Your consent to submit any or all Personal Information. In case, You choose to do so then Your
                        access to the Website may be limited and/or we might not be able to provide the services to You. </p><br />
                    <p><h3>INTELLECTUAL PROPERTY RIGHTS (IPR)</h3></p>
                    <p>Our products, our ideas and concepts, our logo, our Brand Name “ALC Travels” are exclusively and
                        predominantly developed by us. We have Trademarks, Watermarks and Copyrights registered in the
                        name of ALC Travels and our Directors. You agree that You will not use or display any registered IPR
                        without the prior written consent being obtained from Veena World. Any person who attempts to
                        infringe Intellectual Property Rights vested upon us shall face legal consequences. </p><br />
                    <p><h3>AUTHENTICITY OF DATA </h3></p>
                    <p>It shall be Your duty to ensure that the information provided by You is true, accurate and complete. ALC
                        Travels will not be held responsible for the authenticity, accuracy and correctness of such information.
                    </p><br />
                    <p><h3>CHANGES TO THIS PRIVACY POLICY</h3></p>
                    <p>By opening, browsing, using this site for transactions or storing any data/information, You agree to
                        comply with the latest revised privacy policy in effect at such time. The contents of this Privacy Policy
                        are subject to change at any time without notice. Therefore, You have to make sure You have access to
                        latest version of Privacy policy. </p><br />
                    <p>f at any time You believe that we have not adhered to the Privacy Policy, please inform us by e-mail at
                        <a href="mailto:rvermajio78300@gmail.com"> info@alctravels.com</a> and we will take all reasonable efforts and steps to promptly determine and
                        resolve the issue. </p><br />
                </div>
            </div>
            <Address />
            <Footer />
        </>
    )
}

export default PrivacyPolicy

import React, { useEffect, useState } from "react";
import "./style.css";
import { useSelector } from "react-redux";

const Highlights = () => {
  const data = useSelector((state) => state?.tableHeader);
  const bannerSliders = data?.data?.promotionalBanner || [];
  const [activeSlide, setActiveSlide] = useState(0);

  const handleIndicatorClick = (index) => {
    setActiveSlide(index);
  };

  return (
    <React.Fragment>
      <section className="section_banner">
        <div
          id="carouselExampleControls"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div
            className="carousel-indicators"
            style={{ marginBottom: "0px" }}
          >
            {bannerSliders.map((_, index) => (
              <button
                key={index}
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide-to={index}
                className={index === activeSlide ? "active" : ""}
                onClick={() => handleIndicatorClick(index)}
              ></button>
            ))}
          </div>
          <div className="carousel-inner">
            {bannerSliders.map((item, index) => (
              <div
                className={`carousel-item ${index === activeSlide ? "active" : ""
                  }`}
                key={index}
              >
                {item.banner_image && (
                  <img
                    src={`https://admin.alctravels.com/storage/${item.banner_image}`}
                    className="d-block w-100 highlight-img"
                    alt={`Slide ${index}`}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Highlights;

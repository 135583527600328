import React from "react";
import "./style.css";


const Features = (props) => {
    const heading_alignment = props.alignment == undefined ? "" : props.alignment;
    return (
        <React.Fragment>
        <section className="section_features">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 heading">
                        <h3 style={{fontWeight : "bold"}} className={heading_alignment}>Why ALC</h3>
                    </div>
                </div>

                <div className="row justify-content-center img-container">
                    <div className="col-lg-2 col-md-4 col-6 feature">
                        <div className="rounded-circle feature-circle">
                            <img src="./location-icon.svg" alt="feature icon" className="w-75" />
                        </div>
                        <p>WORLDWIDE REACH</p>
                    </div>
                    <div className="col-lg-2 col-md-4  col-6 feature">
                        <div className="rounded-circle feature-circle">
                            <img src="./experienced-team.svg" alt="feature icon" className="w-75" />
                        </div>
                        <p>Experienced team</p>
                    </div>
                    <div className="col-lg-2 col-md-4 col-6 feature">
                        <div className="rounded-circle feature-circle">
                            <img src="./trusted.svg" alt="feature icon" className="w-75" />
                        </div>
                        <p>trusted over years</p>
                    </div>
                    <div className="col-lg-2 col-md-4 col-6 feature">
                        <div className="rounded-circle feature-circle">
                            <img src="./support.svg" alt="feature icon" className="w-75" />
                        </div>
                        <p>24X7 SUPPORT</p>
                    </div>
                    <div className="col-lg-2 col-md-4 col-6 feature">
                        <div className="rounded-circle feature-circle">
                            <img src="./recognized.svg" alt="feature icon" className="w-75" />
                        </div>
                        <p>RECOGNIZED</p>
                    </div>
                    <div className="col-lg-2 col-md-4 col-6 feature">
                        <div className="rounded-circle feature-circle">
                            <img src="./happy.svg" alt="feature icon" className="w-75" />
                        </div>
                        <p>5L+ happy travelers</p>
                    </div>
                </div>
            </div>
        </section>
        </React.Fragment>
    )
};

export default Features;
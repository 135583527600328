import React from "react";
import "./style.css";
import "bootstrap-icons/font/bootstrap-icons.css";

const SocialSideBar = () => {
    return (
        <React.Fragment>
            <div className="main">
                <div className="side_social_icon d-flex justify-content-evenly flex-column">
                    <div>
                        <a href="https://www.facebook.com/alctravelsonline/" target="_blank" className="d-flex flex-column"><i className="bi bi-facebook"></i></a>
                    </div>
                    <div>
                        <a href="https://www.instagram.com/alctravelsonline/" target="_blank" className="d-flex flex-column"><i className="bi bi-instagram"></i></a>
                    </div>
                    <div>
                        <a href="https://www.linkedin.com/company/alctravelsonline" target="_blank" className="d-flex flex-column"><i className="bi bi-linkedin"></i></a>
                    </div>
                    <div>
                        <a href="#" target="_blank" className="d-flex flex-column"><i className="bi bi-youtube"></i></a>
                    </div>
                    <div>
                        <a href="https://wa.me/919555423232" target="_blank" className="d-flex flex-column"><i className="bi bi-whatsapp"></i></a>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default SocialSideBar;

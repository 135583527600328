import React from "react";
import "./style.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Connects = () => {
  let data = useSelector((state) => state?.tableHeader);
  const featuredDestinations = data?.data?.featuredDestinations || [];
  console.log(featuredDestinations)

  return (
    <React.Fragment>
      <section className="section_maldives">
        {featuredDestinations.length < 1 ? (<></>) : (<div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 mb-4">
              <Link to={'/package/' + featuredDestinations[0]?.destinations?.destination_slug + '/' + featuredDestinations[0]?.destinations?.destination_id}
                state={{ 'id': featuredDestinations[0]?.destinations?.destination_id, "name": featuredDestinations[0]?.destinations?.destination_name, "bannerImage": featuredDestinations[0]?.destinations?.destination_banner_image, "cardImage": featuredDestinations[0]?.destinations?.destination_card_image }}>
                <span className="card1_info">
                  <img src={`https://admin.alctravels.com/storage/${featuredDestinations[0]?.featured_destination_image}`} alt="" className="w-100" />
                  <p>{featuredDestinations[0]?.featured_destination_name}</p>
                </span>
              </Link>
            </div>

            <div className="col-lg-8">
              <div className="row">
                {Array.from({ length: 6 }, (_, index) => (
                  <div className="col-lg-4 col-md-6 col-6 mb-4" key={index}>
                    <Link to={'/package/' + featuredDestinations[index + 1]?.destinations?.destination_slug + '/' + featuredDestinations[index + 1]?.destinations?.destination_id} state={{ 'id': featuredDestinations[index + 1]?.destinations?.destination_id, "name": featuredDestinations[index + 1]?.destinations?.destination_name, "bannerImage": featuredDestinations[index + 1]?.destinations?.destination_banner_image, "cardImage": featuredDestinations[index + 1]?.destinations?.destination_card_image }}>
                      <div className="card_info">
                        <img src={`https://admin.alctravels.com/storage/${featuredDestinations[index + 1]?.featured_destination_image}`} alt="" className="w-100" />
                        <p>{featuredDestinations[index + 1]?.featured_destination_name}</p>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>)}
      </section >
    </React.Fragment>
  );
};

export default Connects;

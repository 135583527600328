import { Link } from "react-router-dom";
import "./style.css";
import React from "react";

const Address = () => {
  return (
    <React.Fragment>
      <section className="section_address">
        <div className="container-fluid">
          <div className="row">
            <div className="col-6 col-xl-3">
              <div className="address">
                <div className="title">
                  <img src="../../../buildings.svg" alt="" className="w-100" />
                  <h2>Corporate Office</h2>
                </div>
                <p>
                  404, Bhikaji Bhawan, Bhikaji Cama Place, New Delhi 110066 (India)
                </p>
                <a href="https://www.google.com/maps?ll=28.568766,77.187068&z=14&t=m&hl=en&gl=IN&mapclient=embed&cid=16360612708431680179" target="_blank"><span>View On Google Map</span></a>
              </div>
            </div>
            <div className="col-6 col-xl-3">
              <div className="address">
                <div className="title">
                  <img src="../../../call.svg" alt="" className="w-100" />
                  <h2>Call Us (Toll Free )</h2>
                </div>
                <p>
                  Request a quote, or just chat about your next vacation. We're
                  always happy to help!
                </p>
                <a href="tel:1800-309-9975"><span>1800 309 9975</span></a>
              </div>
            </div>
            <div className="col-6 col-xl-3">
              <div className="address">
                <div className="title">
                  <img src="../../../email.svg" alt="" className="w-100" />
                  <h2>Write to Us</h2>
                </div>
                <p>
                  Send us an enquiry, feedback or a simple suggestion, write to us.
                </p>
                <a href="https://mail.google.com/mail/?view=cm&fs=1&to=info@alctravels.com" target="_blank"><span>info@alctravels.com</span></a>
              </div>
            </div>
            <div className="col-6 col-xl-3">
              <div className="address">
                <div className="title">
                  <img src="../../../share.svg" alt="" className="w-100" />
                  <h2>Connect with us</h2>
                </div>
                <p>
                  Follow us on social media for latest updates, blog, reviews and
                  offers...
                </p>
                <span className="connect">
                  <a href="https://www.facebook.com/alctravelsonline/">
                    <img src="../../../facebook-circular-logo.svg" alt="" />
                  </a>
                  <a href="https://www.instagram.com/alctravelsonline/">
                    <img src="../../../instagram.svg" alt="" />
                  </a>
                  <a href="https://www.linkedin.com/company/alctravelsonline">
                    <img src="../../../linkedin.svg" alt="" />
                  </a>
                  <a href="">
                    <img src="../../../youtube.svg" alt="" />
                  </a>
                  <a href="">
                    <img src="../../../whatsapp.svg" alt="" />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default Address;

import { Link } from "react-router-dom";
import InboxForm from "./InboxForm";
import "./style.css";
import { useSelector } from "react-redux";
import profilePDF from "./profile.pdf"
import React from "react";

const Footer = () => {
  const data = useSelector((state) => state?.tableHeader);
  const footerData = data?.data?.trendingDestinations || [];
  return (
    <React.Fragment>
      <section className="section_footer">

        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-2 col-lg-6 col-md-6 mb-3">
              <h2>Our Services</h2>
              <p>Holiday Packages</p>
              <p>Meetings & Incentives</p>
              <p>Conferences & Events</p>
              <p>Destination Wedding</p>
              <p>Worldwide Cruise</p>
              <p>Scenic Trains</p>
              <p>Destination Management</p>
              <p>Golf Holidays</p>
              <p>LTC Tours</p>
              <p>Flights & Hotels Booking</p>
              <p>Visa Consultancy Services</p>
              <p>Travel Insurance</p>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 mb-3">
              <h2>Trending Destinations</h2>
              <div className="row">
                <div className="col-xl-4 d-flex flex-wrap trending-destinations">
                  {footerData.map((elem, index) => (
                    index < 12 && <Link className="para" key={index} to={'/package/' + elem.destination_name + "/" + elem.destination_id}
                    >{elem.destination_name}</Link>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-6 col-md-6 mb-3">
              <h2>Discover us</h2>

              <Link to={"/about-us"}><p>About Us </p></Link>
              <Link to={"https://alcgroup.co.in"}><p> Our Group </p></Link>
              <Link to={"/blog"}><p>Trending Blogs </p></Link>
              <a href="https://alcfoundation.org" target="_blank"><p>CSR Initiatives </p></a>
              <a href="/carrer"><p>Careers “We’re Hiring” </p></a>
              <Link to={profilePDF} target="_blank"><p>Company Profile</p></Link>
              <Link to={"/termsandconditions"}><p>Terms & Conditions</p></Link>
              <Link to={"/privacyandpolicy"} target="_blank"><p>Privacy & Policy</p></Link>
              <Link to={"#"}><p>Disclaimer </p></Link>
              <Link to={"/contact-us"} ><p>Contact Us</p></Link>
              <Link to={"/blog"} ><p>Blogs</p></Link>
            </div>
            <div className="col-xl-5 col-lg-6 col-md-6 mb-3">
              <img src="../../../white-logo.svg" alt="" className="w-100 white-logo" />
              <p style={{ textAlign: "center" }}>
                India's Leading Travel Management Company.<br />

                You dream to fly and we make it possible. Exploring different parts of the world is a dream of every travel enthusiast and we endeavor to make the process streamlined for everyone.
              </p>
              <h3>Get best travel deals in your inbox</h3>
              <span>
                Subscribe to our newsletter to find travel deals in your inbox.
              </span>
              <InboxForm />
            </div>
            <div className="col-lg-12">
              <hr />
            </div>
          </div>
          <div className="row copyright justify-content-center">
            <div className="col-lg-6 mb-3" style={{ width: "auto" }}>
              <p>Copyright © 2014 - 2024 ALC Travels | All Rights Reserved | Developed By <a href="https://www.alcinfotech.com/" target="_blank">ALC InfoTech</a></p>
            </div>
          </div>
        </div>

      </section>
    </React.Fragment>
  );
};

export default Footer;

import Address from "../../Components/Address";
import BlogCard from "../../Components/BlogCard";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import SocialSideBar from "../../Components/SocialSideBar";
import { useSelector } from "react-redux";
import "./style.css";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const Blog = () => {
  const location = useLocation();
  const canonicalUrl = `https://alctravels.com${location.pathname}`

  let data = useSelector((state) => state?.tableHeader);
  const blogs = data?.data?.blogs || [];
  const [pageNum, setPageNum] = useState(1);
  const lastPage = 1;
  const pageTitle = "ALC Travels - Blogs";
  window.scrollTo(0,0);

  const HandleNextPage = () => {
    setPageNum(pageNum + 1);
  }

  const HandlePrevPage = () => {
    setPageNum(pageNum - 1);
  }

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <SocialSideBar/>
      <Header />
      <div className="page-banner">
        <img src="./blogbanner.svg" className="w-100" alt=""></img>
        <div className="pagebanner_heading">
          <h1>Latest Travel & Destination Blog</h1>
        </div>
      </div>
      <div className="section_blog">
        <div className="container-fluid">
          <div className="row">
            {blogs.map((eachBlog) => (
              <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <BlogCard
                  id={eachBlog.id}
                  description={eachBlog.description}
                  image={eachBlog.featured_image}
                  title={eachBlog.title}
                  date={eachBlog.created_at}
                  pageUrl={eachBlog.page_url}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="pagination_card">
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li className="page-item page-circle">
              <button className="btn btn-primary" type="button" disabled={pageNum <= 1} onClick={HandlePrevPage}>&larr;</button>
            </li>
            <li className="page-item page-circle">
              <a className="page-link" href="#">
                {pageNum}
              </a>
            </li>
            <li className="page-item page-circle">
              <button className="btn btn-primary" type="button" disabled={pageNum >= lastPage || lastPage == undefined} onClick={HandleNextPage}>&rarr;</button>
            </li>
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div className="mt-5">
        <Address />
      </div>
      <Footer />
    </div>
  );
};

export default Blog;

import React, { useEffect, useState } from "react";
import "./index.css"
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useDispatch } from "react-redux";
import { CONTACTFORM, TABLEHEADER } from "../../redux/types";
import Contact from "../../services/Contact";

const SideBarContactForm = () => {
    const [contactValue, setcontactValue] = useState();
    const [full_name, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [description, setDescription] = useState("");
    const [submit, setSubmit] = useState(false);
    const dispatch = useDispatch();

    const handleSubmit = (e) => {
        setSubmit(true);
        e.preventDefault();
        Contact.newContact(full_name, contactValue, email, description)
            .then((res) => {
                dispatch({
                    type: CONTACTFORM,
                    payload: res,
                });
                console.log(res);
                document.getElementById('alertMessage').innerText = res.message;
                document.getElementById('alertMessage').style.display = "block";
                setSubmit(false);

                setTimeout(() => {
                    document.getElementById('alertMessage').style.display = "none";
                }, 3000)
            })
            .catch((error) => {
                console.log("error", error);
                document.getElementById('alertMessage').innerText = error.message;
                document.getElementById('alertMessage').style.display = "block";
                setSubmit(false);

                setTimeout(() => {
                    document.getElementById('alertMessage').style.display = "none";
                }, 3000)
            });
    }
    return (
        <React.Fragment>
            <div className="alert alert-warning alert-dismissible fade show" role="alert" id="alertMessage" style={{ display: "none" }}>
                alert text
            </div>
            <form className="contact-form" onSubmit={handleSubmit}>
                <h2>Fill these details & we will get back to you.</h2>
                <input
                    placeholder="Full Name"
                    type="text"
                    className="form-control inputFld mb-4"
                    onChange={(e) => setFullName(e.target.value)}
                    required
                />
                <div className="phone-input">
                    <PhoneInput
                        autocompleteSearch={true}
                        country={'in'}
                        value={contactValue}
                        onChange={setcontactValue}
                    />
                </div>
                <input
                    placeholder="Email Id"
                    type="email"
                    className="form-control inputFld mb-4"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <textarea className="form-control inputFld mb-4" placeholder="Description" rows="3" onChange={(e) => setDescription(e.target.value)} required></textarea>

                <button type="submit" disabled={submit} className="btn btn-primary mb-1">
                    {!submit ? "SUBMIT" : <div className="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>}
                </button>
            </form>
        </React.Fragment>
    );
};
export default SideBarContactForm;

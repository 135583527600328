import ContactForm from "../ContactForm";
import { Link } from "react-router-dom";
import "./style.css";
import React from "react";

const CruiseCard = (props) => {
  const title = props.title;
  const dayNight = props.dayNight;
  const image = props.cruiseImage;
  const price = props.cruisePrice;
  const pdf = props.cruisePdf;
  const formId = "form"+props.cruiseId;
  console.log(pdf)
  return (
    <React.Fragment>
    <div className="cruise_card">
      <div className="cruise_card-inner">
        <div className="cruiseimg">
          <img src={`https://admin.alctravels.com/storage/${image}`} alt="" className="w-100" />
        </div>
        <div className="price">
          <h2 style={{textAlign : "center"}}>{title}</h2>
          <p>{dayNight}</p>
          <p>Premium</p>
          <div className="inner">
            <div className="inner_head">
              <h3>{"₹"+price}</h3>
              <span>Per Person</span>
            </div>
            <div className="buttoninfo">
              <button><a href={`https://admin.alctravels.com/storage/${pdf}`} target="_blank" >DOWNLOAD PDF</a></button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target={"#" + formId} //through props
              >
                CONTACT NOW
              </button>
              <div
                className="modal fade"
                id={formId} //through props
                tabIndex={-1}
                aria-labelledby={formId+"Label"}
                aria-hidden="true"
              >
                <div className="modal-dialog d-flex jusitfy-content-center align-items-center h-100">
                  <div className="modal-content">
                    <div className="modal-body">
                      <ContactForm description={props.title} popUpImage={props.popUpImage}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </React.Fragment>
  );
};
export default CruiseCard;

import "./style.css";
import React, { useEffect, useState } from "react";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useDispatch } from "react-redux";
import Contact from "../../services/Contact";
import { CONTACTFORM } from "../../redux/types";
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = (props) => {
  const [contactValue, setcontactValue] = useState();
  const popUpImage = "https://admin.alctravels.com/storage/" + props?.popUpImage?.ads_image || "";
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    const full_name = document.getElementById('full_name').value;
    const mob_number = contactValue;
    const email = document.getElementById('email').value;
    const description = document.getElementById('description').value;

    Contact.newContact(full_name, mob_number, email, description)
      .then((res) => {
        dispatch({
          type: CONTACTFORM,
          payload: res,
        });
        console.log(res);
        alert(res.message)
      })
      .catch((error) => {
        console.log("error", error);
      });
    e.preventDefault();
  }

  function onChange(value) {
    console.log("Captcha value:", value);
  }
  return (
    <React.Fragment>
      <div className="d-flex pop-up justify-content-around" style={{zIndex : "99"}}>
        <div className="popImage col-lg-4">
          <img src={popUpImage} alt="" className="w-100" style={{zIndex : "10"}}/>
        </div>
        <form className="contact-form" onSubmit={handleSubmit}>
          <h2>Fill these details & we will get back to you.</h2>
          <input
            placeholder="Full Name"
            type="text"
            className="form-control inputFld mb-4"
            id="full_name"
            required
          />
          <div className="phone-input">
            <PhoneInput
              autocompleteSearch={true}
              country={'in'}
              value={contactValue}
              onChange={setcontactValue}
            />
          </div>
          <input
            placeholder="Email Id"
            type="email"
            className="form-control inputFld mb-4"
            id="email"
            required
          />
          <textarea className="form-control inputFld mb-4" value={props.description} placeholder="Description" rows="3" id="description"></textarea>
          <ReCAPTCHA
            sitekey="6Lei3FMpAAAAAKIdvlGiJzfkySjstpJ40_cn2lrJ"
            onChange={onChange}
            style={{margin : "0px 34px"}}
          />

          <button type="submit" data-bs-dismiss="modal" className="btn btn-primary mb-1">
            SUBMIT
          </button>
        </form>
      </div>
    </React.Fragment>
  );
};
export default ContactForm;

import { Link } from "react-router-dom";
import "./style.css"
import dateFormat from 'dateformat';
import React from "react";


const BlogCard = ({ id, description, image, title, date, pageUrl }) => {
  return (
    <React.Fragment>
      <div className="our-blog my-3 ">
        <Link to={"/blog/" + id + "/" + pageUrl} state={{ 'title': title }}>
          <div className="main-img">
            <img src={"https://admin.alctravels.com/storage/" + image} alt="" className="w-100" />
          </div>
        </Link>
        <div className="info">
          <Link to={"/blog/" + id + "/" + pageUrl} state={{ 'title': title }}><h5 style={{ color: "black" }}>{title}</h5></Link>
          <span>{dateFormat(date, "mmmm dS, yyyy")}</span>
          <p style={{ fontSize: "16px" }}>
            {getText(description).slice(0, 95) + "....."}
          </p>
          <Link to={"/blog/" + id + "/" + pageUrl} state={{ 'title': title }}>READ MORE</Link>
        </div>
      </div>
    </React.Fragment>
  )

  function getText(html) {
    var divContainer = document.createElement("div");
    divContainer.innerHTML = html;
    return divContainer.textContent || divContainer.innerText || "";
  }

};

export default BlogCard
import React from "react";
import "./style.css";

const PackageDeal = (props) => {

  return (
    <React.Fragment>
      <div className="row package_deal">
        {props.selectDeal.find((element) => {
          return element == "Highlights"
        }) ? <div className="col-6 col-lg-4 col-md-4 col-xl-2 destination">
          <div className="rounded-circle destination-circle">
            <img
              src="../../../highlight.svg"
              alt="destination icon"
              className="w-100"
            />
          </div>
          <p style={{ display: window.innerWidth > 900 ? "block" : "none" }}>highlights</p>
        </div> : <></>}

        {props.selectDeal.find((element) => {
          return element == "Flights"
        }) ? <div className="col-6 col-lg-4 col-md-4 col-xl-2 destination">
          <div className="rounded-circle destination-circle">
            <img
              src="../../../flight.svg"
              alt="destination icon"
              className="w-100"
            />
          </div>
          <p style={{ display: window.innerWidth > 900 ? "block" : "none" }}>Flights</p>
        </div> : <></>}

        {props.selectDeal.find((element) => {
          return element == "Hotels"
        }) ? <div className="col-6 col-lg-4 col-md-4 col-xl-2 destination">
          <div className="rounded-circle destination-circle">
            <img
              src="../../../hotel.svg"
              alt="destination icon"
              className="w-100"

            />
          </div>
          <p style={{ display: window.innerWidth > 900 ? "block" : "none" }}>Hotels</p>
        </div> : <></>}

        {props.selectDeal.find((element) => {
          return element == "Sightseeing"
        }) ? <div className="col-6 col-lg-4 col-md-4 col-xl-2 destination">
          <div className="rounded-circle destination-circle">
            <img
              src="../../../sight.svg"
              alt="destination icon"
              className="w-100"

            />
          </div>
          <p style={{ display: window.innerWidth > 900 ? "block" : "none" }}>sightseeing</p>
        </div> : <></>}

        {props.selectDeal.find((element) => {
          return element == "Visa"
        }) ? <div className="col-6 col-lg-4 col-md-4 col-xl-2 destination">
          <div className="rounded-circle destination-circle">
            <img
              src="../../../visa.svg"
              alt="destination icon"
              className="w-100"
            />
          </div>
          <p style={{ display: window.innerWidth > 900 ? "block" : "none" }}>Visa</p>
        </div> : <></>}

        {props.selectDeal.find((element) => {
          return element == "Meals"
        }) ? <div className="col-6 col-lg-4 col-md-4 col-xl-2 destination">
          <div className="rounded-circle destination-circle">
            <img
              src="../../../meals.svg"
              alt="destination icon"
              className="w-100"

            />
          </div>
          <p style={{ display: window.innerWidth > 900 ? "block" : "none" }}>Meals</p>
        </div> : <></>}
      </div>
    </React.Fragment>
  );
};
export default PackageDeal;

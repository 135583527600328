import "./App.css";
import Home from "./Containers/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/js/bootstrap.bundle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Destination from "./Containers/Destination";
import Cruise from "./Containers/Cruise";
import Blog from "./Containers/Blog";
import Career from "./Containers/Career";
import PrivacyAndPolicy from "./Containers/PrivacyAndPolicy";
import About from "./Containers/About";
import Mice from "./Containers/Mice";
import BlogDetail from "./Containers/BlogDetail";
import Inbound from "./Containers/Inbound";
import ContactUs from "./Containers/ContactUs";
import DetailedPackage from "./Containers/DetailedPackage";
import Payment from "./Containers/Payment";
import GeneratePdf from "./Containers/GeneratePdf";
import PageNotFound from "./Containers/PageNotFound";
import PrivacyPolicy from "./Containers/PrivacyPolicy";
import TermsAndConditions from "./Containers/Terms&Conditions";
import DestinationWedding from "./Containers/DestinationWeddings";
import PaymentStatus from "./Containers/PaymentStatus";

function App() {
  return (
    <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/destination" element={<Destination />} /> */}
          <Route path="/package/:pageurl/:tourId/:pagename" element={<DetailedPackage />} />
          <Route path="/package/:pageurl/:destinationId" element={<Inbound />} />
          <Route path="/cruise" element={<Cruise />} />
          <Route path="/blog/:blogId/:pageurl" element={<BlogDetail />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/career" element={<Career />} />
          <Route path="/privacy-and-policy" element={<PrivacyAndPolicy />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/mice" element={<Mice />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/payment-status" element={<PaymentStatus />} />
          <Route path="/pdf/:pageId" element={<GeneratePdf />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/privacyandpolicy" element={<PrivacyPolicy />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          <Route path="/destination-wedding" element={<DestinationWedding/>} />
        </Routes>
    </div>
  );
}

export default App;

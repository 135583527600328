import { Helmet } from "react-helmet";
import Address from "../../Components/Address";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import SocialSideBar from "../../Components/SocialSideBar";
import OurClient from "../../Components/Home/OurClient";
import "./style.css";
import { useLocation } from "react-router-dom";

const About = () => {
  const location = useLocation();
  const canonicalUrl = `https://alctravels.com${location.pathname}`

  window.scrollTo(0, 0)

  const pageTitle = "ALC Travels - About Us";

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <SocialSideBar />
      <Header />
      <div className="page-banner">
        <img src="../about.svg" className="w-100" alt="" />
        <div className="pagebanner_heading">
          <h1>About Us</h1>
        </div>
      </div>

      <div className="section_about">
        <div className="container-fluid">
          <p>
            <b>You dream to fly and we make it possible!</b><br />
            Exploring different parts of the world is a dream of every travel enthusiast and we endeavor to
            make the process streamlined for everyone. ALC is the epitome of perfection in the travel
            industry. We take delight in surpassing the expectations of our clients by offering them carefully
            curated and meticulously planned adventure trips transforming their lives and curating
            experiences that are meant exclusively for them.
            We are experts in crafting exclusive and personalized luxury and MICE travel endeavored to cater
            to the out-of-the-box demands of the high-end and corporate clients alike. Our experts are
            pragmatically engaged in devising the most luxurious experience surpassing the expectations of
            the clients. For corporates, we exquisitely design the MICE events by adding leisure elements
            alongside the elements including innovation, creativity, effective strategy, and latest technology.<br />
            <h2 className="mt-3 pb-1">Our Excellence</h2>
            <p>
              Since its inception in 2014, the company has become the owner of multiple brands & and
              companies including ALC Travels, ALC MICE, WellBro Care, ALC InfoTech, and ALC Falcon.
              With a strong determination to offer an end-to-end travel service to the clients, Mr. Prabhat
              Singh, founder of the ALC Travel Group, has designed each service with utmost precision favoring
              the benefits of the customers. This has led to the gain of a long list of clientele, who admire us
              for our exceptional travel services available at affordable prices.
              ALC Travel Group is regarded as a dependable name in the Indian travel industry owing to its
              accreditations including IATA, Ministry of Tourism- Govt. of India, SKAL, and OTOAI.
            </p>
          </p>
          <div className="row mt-5 our_vision">
            <div className="col-12 col-md-12 col-lg-12 col-xl-4">
              <div className="vision_img">
                <img src="../our-vision.svg" className="w-100" alt="" />
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12 col-xl-8  mb-5 ">
              <h2>Our Vision</h2>
              <p>
                We envision becoming a benchmark in the travel industry by offering unmatchable experiences to customers worldwide. We aim to meet each requirement of the client ensuring utmost satisfaction at their end. Our vision is to reach 1 Crore people throughout the globe.
              </p>
              <h2>Our Mission</h2>
              <p>
                At ALC Travel Group, our mission is to transform the way people travel by providing smart and
                efficient travel solutions for everyone. We aim to become #1 in the travel industry.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section_leaders">
        <div className="container-fluid">
          <h2 style={{textAlign : "center"}}>Our Leaders</h2>
          <p style={{textAlign : "center"}}>The thought pioneers that inspire & shape us</p>
          <div className="row mt-4 justify-content-evenly">
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 text-center mb-4 py-2">
              <img src="../prabhat.jpg" className="w-100 inner-img" alt="" />
              <h3>Prabhat Singh</h3>
              <span>MD & CEO</span>
              <div className="social">
                <a target="_blank" href="https://www.linkedin.com/in/prabhat-singh-aa703563/"><img src="../linkdlin-1.svg" className="" alt="" /></a>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 text-center mb-4 py-2">
              <img src="../praveen.jpg" className="w-100 inner-img" alt="" />
              <h3>Pravin Singh Rathore</h3>
              <span>Director</span>
              <div className="social">
                <a target="_blank" href="https://www.linkedin.com/in/pravin-singh-rathore-b5b52363/"><img src="../linkdlin-1.svg" className="" alt="" /></a>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 text-center mb-4 py-2">
              <img src="../vineet.jpg" className="w-100 inner-img" alt="" />
              <h3>Vineet Nijhon</h3>
              <span>Director</span>
              <div className="social">
                <a target="_blank" href="https://www.linkedin.com/in/vineet-nijhon-43047423/"><img src="../linkdlin-1.svg" className="" alt="" /></a>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 text-center mb-4 py-2">
              <img src="../mithilesh.jpg" className="w-100 inner-img" alt="" />
              <h3>Mithilesh Ojha</h3>
              <span>Assistant Vice President</span>
              <div className="social">
                <a target="_blank" href="https://www.linkedin.com/in/mithilesh-ojha-50921330/"><img src="../linkdlin-1.svg" className="" alt="" /></a>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 text-center mb-4 py-2">
              <img src="../pradeep.jpg" className="w-100 inner-img" alt="" />
              <h3>Pradeep S Bisht</h3>
              <span>General Manager</span>
              <div className="social">
                <a href="https://www.linkedin.com/in/pradeep-s-bisht-8123572a4/"><img src="../linkdlin-1.svg" className="" alt="" /></a>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 text-center mb-4 py-2">
              <img src="../Raghvendra .jpg" className="w-100 inner-img" alt="" />
              <h3>Raghvendra Singh</h3>
              <span>Independent Director</span>
              <div className="social">
                <a href="https://www.facebook.com/raghvendrarss?mibextid=qi2Omg&rdid=yuMcUABzrDC1Rmcs"><img src="../linkdlin-1.svg" className="" alt="" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about_client">
        <OurClient />
      </div>

      <div className="mt-5">
        <Address />
      </div>
      <Footer />
    </div>
  );
};

export default About;
